import classNames from "classnames";
import { UserChat } from "../core/models";

interface UserMessageProps {
  userChat: UserChat;
  className?: string;
}

export default function UserMessage({ userChat, className }: UserMessageProps) {
  return (
    <div
      className={classNames(
        className,
        { "bg-gray-100": userChat.userName === "Lavern Laboy" },
        "py-2 px-3 flex justify-between items-center w-full"
      )}
    >
      <div className="flex items-center gap-x-4 min-w-full">
        <img className="rounded-md h-12 w-12" src={userChat.urlImage} alt="" />
        <div className="flex flex-col gap-y-1 w-full">
          <div className=" flex flex-row items-center justify-between">
            <h3 className="text-secondary-100 text-sm font-semibold">
              {userChat.userName}
            </h3>
            <h5 className=" text-xs text-gray-400 font-semibold rounded-xl">
              {userChat.time}
            </h5>
          </div>
          <p className="text-[10px] text-gray-500 font-normal">
            {userChat.description}
          </p>
        </div>
      </div>
    </div>
  );
}
