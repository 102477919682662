import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import { SettingIcon } from "../components/icons";
import { useLayoutContext } from "../core/context/layout-context";
import { NavigationInterface } from "../core/models";
import { useThemeContext } from "../core/context/theme-context";

interface AsideProps {
  openAside: boolean;
}

export default function Aside({ openAside }: AsideProps) {
  const location = useLocation();
  const { selectChildren, navigations, handleChildren } = useLayoutContext();
  const { color } = useThemeContext();

  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const currentNavigation = navigations.find(
    (el: any) => el.href === location.pathname
  );

  const childrenElements = currentNavigation
    ? //@ts-ignore
      currentNavigation.children.map((item: NavigationInterface, i: number) => {
        return (
          <li key={item.name} className="w-full">
            <div
              onClick={() => handleChildren(item.href)}
              className={classNames(
                selectChildren === item.href &&
                  `${
                    color === "green"
                      ? "bg-green-100 text-green-700"
                      : color === "purple"
                      ? "bg-purple-100 text-purple-700"
                      : "bg-gray-100 text-gray-700"
                  }`,
                `${
                  color === "green"
                    ? "hover:bg-green-100"
                    : color === "purple"
                    ? "hover:bg-purple-100"
                    : "hover:bg-gray-100"
                }`,
                " group flex gap-x-3 items-center rounded-md p-3 cursor-pointer text-sm leading-6 font-semibold group mx-2"
              )}
            >
              <item.icon
                className={classNames(
                  i === 0 &&
                    `${
                      color === "green"
                        ? "text-green-600"
                        : color === "purple"
                        ? "text-purple-600"
                        : "text-gray-600"
                    }`,
                  location.pathname === item.href
                    ? `${
                        color === "green"
                          ? "text-green-600"
                          : color === "purple"
                          ? "text-purple-600"
                          : "text-gray-600"
                      }`
                    : `${
                        color === "green"
                          ? "text-green-500"
                          : color === "purple"
                          ? "text-purple-500"
                          : "text-gray-500"
                      }`,
                  `${
                    color === "green"
                      ? "group-hover:text-green-600"
                      : color === "purple"
                      ? "group-hover:text-purple-600"
                      : "group-hover:text-gray-600"
                  }`,
                  "h-6 w-6 shrink-0 "
                )}
                aria-hidden="true"
              />
              <span
                className={classNames(
                  { "sr-only": !openAside },
                  `${
                    color === "green"
                      ? "group-hover:text-green-600"
                      : color === "purple"
                      ? "group-hover:text-purple-600"
                      : "group-hover:text-gray-600"
                  }`
                )}
              >
                {item.name}
              </span>
            </div>
            {location.pathname === "/campagnes" && i === 2 ? (
              <hr className="border-dashed mb-2 border-purple-100" />
            ) : (
              <></>
            )}
          </li>
        );
      })
    : null;

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <a href="/">
                      <span className="text-green-600 text-xl font-semibold">
                        KU
                      </span>
                    </a>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="-mx-2 flex-1 space-y-1">
                      {navigations.map((elmt) => {
                        const item = elmt as NavigationInterface;

                        return (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                "text-gray-400 hover:text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          { "lg:w-40": openAside },
          { "lg:w-20": !openAside },
          "transition-all duration-300 ease-in-out hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-40 lg:block  lg:overflow-y-auto bg-white shadow-xl lg:pb-4"
        )}
      >
        <div className="flex p-2 m-2 rounded-2xl shrink-0 items-center justify-center">
          <a href="/">
            <span
              className={classNames(
                `${
                  color === "green"
                    ? "text-green-600"
                    : color === "purple"
                    ? "text-purple-600"
                    : "text-gray-600"
                }`,
                "text-xl font-semibold"
              )}
            >
              KU
            </span>
          </a>
        </div>
        <nav className="mt-8 flex flex-col items-center justify-between h-[700px] w-full">
          <ul
            role="list"
            className={classNames(
              { "items-start w-full": openAside },
              { "items-center": !openAside },
              "flex flex-col  space-y-1"
            )}
          >
            {childrenElements}
          </ul>
          <ul>
            <li>
              <a
                href={"#"}
                className={classNames(
                  `${
                    color === "green"
                      ? "hover:bg-green-100"
                      : color === "purple"
                      ? "hover:bg-purple-100"
                      : "hover:bg-gray-100"
                  }`,
                  "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold  group"
                )}
              >
                <SettingIcon
                  className={classNames(
                    `${
                      color === "green"
                        ? "text-green-500 group-hover:text-green-600"
                        : color === "purple"
                        ? "text-purple-500 group-hover:text-purple-600"
                        : " text-gray-500 group-hover:text-gray-600"
                    }`,
                    "h-6 w-6 shrink-0 "
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    { "sr-only": !openAside },
                    `${
                      color === "green"
                        ? "group-hover:text-green-600"
                        : color === "purple"
                        ? "group-hover:text-purple-600"
                        : "group-hover:text-gray-600"
                    }`
                  )}
                >
                  Settings
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">
          Dashboard
        </div>
      </div>
    </>
  );
}
