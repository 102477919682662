export default function ChatMessage() {
  return (
    <div className="w-full flex-grow my-2 p-2 overflow-y-auto">
      <div className="flex items-end w-3/4">
        <img
          className="hidden w-8 h-8 m-3 rounded-full"
          src="https://randomuser.me/api/portraits/men/20.jpg"
          alt="avatar"
        />
        <div className="w-8 m-3 rounded-full" />
        <div className="p-3 bg-gray-100 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
          <div className="text-black text-xs">Lorem ipsum dolor sit amet</div>
          <div className="text-xs text-gray-400">1 day ago</div>
        </div>
      </div>
      <div className="flex items-end w-3/4">
        <img
          className="w-8 h-8 m-3 rounded-full"
          src="https://randomuser.me/api/portraits/men/20.jpg"
          alt="avatar"
        />
        <div className="p-3 bg-gray-100 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
          <div className="text-black text-xs">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </div>
          <div className="text-xs text-gray-400">1 day ago</div>
        </div>
      </div>

      <div className="flex justify-end">
        <div className="flex items-end w-auto bg-green-600 m-1 rounded-xl rounded-br-none sm:w-3/4 md:w-auto">
          <div className="p-2">
            <div className="text-white text-xs">Hello</div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex items-end w-3/4 bg-green-600 m-1 rounded-xl rounded-br-none sm:w-3/4 md:w-auto">
          <div className="p-2">
            <div className="text-white text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex items-end w-3/4 bg-green-600 m-1 rounded-xl rounded-br-none sm:w-3/4 max-w-xl md:w-auto">
          <div className="p-2">
            <div className="text-white text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-end w-3/4">
        <img
          className="w-8 h-8 m-3 rounded-full"
          src="https://randomuser.me/api/portraits/men/20.jpg"
          alt="avatar"
        />
        <div className="p-3 bg-gray-100 mx-3 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
          <div className="text-black text-xs ">
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </div>
          <div className="text-xs text-gray-400">just now</div>
        </div>
      </div>
    </div>
  );
}
