import React from "react";

export default function VerticalDotIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.6667 20C36.6667 10.8167 29.1834 3.33334 20 3.33334C10.8167 3.33334 3.33335 10.8167 3.33335 20C3.33335 29.1833 10.8167 36.6667 20 36.6667C29.1834 36.6667 36.6667 29.1833 36.6667 20ZM18.3334 13.3333C18.3334 12.4 19.0834 11.6667 20 11.6667C20.9167 11.6667 21.6667 12.4167 21.6667 13.3333C21.6667 14.25 20.9167 15 20 15C19.0834 15 18.3334 14.2667 18.3334 13.3333ZM18.3334 20C18.3334 19.0667 19.0834 18.3333 20 18.3333C20.9167 18.3333 21.6667 19.0833 21.6667 20C21.6667 20.9167 20.9167 21.6667 20 21.6667C19.0834 21.6667 18.3334 20.9333 18.3334 20ZM18.3334 26.6667C18.3334 25.7333 19.0834 25 20 25C20.9167 25 21.6667 25.75 21.6667 26.6667C21.6667 27.5833 20.9167 28.3333 20 28.3333C19.0834 28.3333 18.3334 27.6 18.3334 26.6667Z"
        fill="#BBF7D0"
      />
      <circle cx="20" cy="13" r="2" fill="currentColor" />
      <circle cx="20" cy="20" r="2" fill="currentColor" />
      <circle cx="20" cy="27" r="2" fill="currentColor" />
    </svg>
  );
}
