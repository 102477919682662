interface CreateCampagneProps {
  handleCreate: () => void;
}
export default function CreateCampagne({ handleCreate }: CreateCampagneProps) {
  return (
    <div className="flex flex-col divide-y border">
      <div className="flex items-center justify-between px-5 py-2">
        <input
          type="text"
          className="border rounded-md h-10 px-2 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
          placeholder="Rechercher"
        />
        <button
          onClick={() => handleCreate()}
          className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
        >
          Créer une campagne
        </button>
      </div>
      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
        <img
          src="/assets/images/campagnes/campaignes-1.png"
          alt=""
          className="h-40 w-40"
        />
        <h1 className="text-2xl font-semibold text-purple-600">
          Créez votre première campagne de messages
        </h1>
        <h5 className="text-sm font-normal text-gray-500">
          Envoyez facilement des messages à plusieurs contacts à la fois en
          créant une campagne
        </h5>
        <button
          onClick={() => handleCreate()}
          className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
        >
          Créer une campagne
        </button>
      </div>
    </div>
  );
}
