import React from "react";
import Aside from "./aside";
import Header from "./header";
import Messages from "./messages";
import AsideInfo from "./aside-info";
import Footer from "./footer";
import classNames from "classnames";
import { useLayoutContext } from "../core/context/layout-context";
import { useLocation } from "react-router-dom";

export default function Layout({ children }: React.PropsWithChildren) {
  const { asideOpen, handleOpenAsude } = useLayoutContext();
  const location = useLocation();

  return (
    <section className="w-screen h-screen font-inter">
      <Aside openAside={asideOpen} />
      <Header openAside={asideOpen} setAsideOpen={() => handleOpenAsude()} />
      <main
        className={classNames(
          { "lg:pl-40": asideOpen },
          { "lg:pl-20 ": !asideOpen },
          { "xl:pr-16": location.pathname === "/conversations" },
          " mt-14 transition-all duration-300 ease-in-out h-screen"
        )}
      >
        {children}
      </main>

      <Footer />
    </section>
  );
}
