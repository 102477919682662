export default function DashboardIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 16.99V13.99M8.02 1.84001L2.63 6.04001C1.73 6.74001 1 8.23001 1 9.36001V16.77C1 19.09 2.89 20.99 5.21 20.99H16.79C19.11 20.99 21 19.09 21 16.78V9.50001C21 8.29001 20.19 6.74001 19.2 6.05001L13.02 1.72001C11.62 0.74001 9.37 0.79001 8.02 1.84001Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
