//Conversations
export const DASHBOARD = "#";

//Conversations
export const CONVERSATION_DASHBOARD = "#";
export const CONVERSATION_MESSAGES = "#messages";
export const CONVERSATION_HISTORY = "#history";

//Conversations
export const CAMPAGNE_DASHBOARD = "#";
export const CAMPAGNE_FOLLOW = "#cpg-follow";
export const CAMPAGNE_NEW = "#cpg-new";
export const CAMPAGNE_CONTACT = "#cpg-contact";
export const CAMPAGNE_TEMPLATE = "#cpg-template";
export const CAMPAGNE_NEW_TEMPLATE = "#cpg-new-template";
