import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/dashboard/dashboard-page";
import ConversationsPage from "./pages/conversations/conversations-page";
import { Layout } from "./layouts";
import { LayoutProvider } from "./core/context/layout-context";
import { ThemeProvider } from "./core/context/theme-context";
import CampagnesPage from "./pages/campagnes/campagne-page";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <LayoutProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/conversations" element={<ConversationsPage />} />
              <Route path="/campagnes" element={<CampagnesPage />} />
            </Routes>
          </Layout>
        </LayoutProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
