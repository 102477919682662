import { ChatMessage, InputFormMessage } from "../../../components";
import { CallIcon, VideoIcon } from "../../../components/icons";
import { useLayoutContext } from "../../../core/context/layout-context";
import { Messages } from "../../../layouts";
import AsideInfo from "../../../layouts/aside-info";

export default function Message() {
  const { asideOpen } = useLayoutContext();

  return (
    <main className="xl:px-80">
      <div className="h-screen">
        <div className="flex flex-col sticky top-10 w-full bg-white">
          <div className="px-4 sm:px-6 lg:px-4 py-10 lg:py-2">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-x-4 min-w-full">
                <img
                  className="rounded-md h-12 w-12"
                  src="https://randomuser.me/api/portraits/men/20.jpg"
                  alt=""
                />
                <div className="flex flex-col md:flex-row md:items-center gap-2 justify-between w-full">
                  <div className="flex flex-col w-full">
                    <h3 className="text-secondary-100 text-lg font-semibold">
                      Lavern Laboy
                    </h3>
                    <span className="flex items-center gap-x-1 text-xs text-gray-500 font-normal">
                      <hr className="p-1 bg-green-400 rounded-full" /> Online
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <div className="bg-green-100 flex items-center px-4 py-1 rounded-md gap-x-2">
                      <CallIcon className="text-green-600 h-4 w-4" />
                      <span className="text-green-600">Audio</span>
                    </div>
                    <div className="bg-green-100 flex items-center px-4 py-1 rounded-md gap-x-2">
                      <VideoIcon className="text-green-600 h-4 w-4" />
                      <span className="text-green-600">Video</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="flex-grow h-full w-full flex flex-col mt-16">
          <ChatMessage />
          <InputFormMessage />
        </div>
      </div>
      <AsideInfo />
      <Messages openAside={asideOpen} />
    </main>
  );
}
