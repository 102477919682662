import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importe les styles par défaut de ReactQuill

const Editor = () => {
  const [content, setContent] = useState("");

  return (
    <ReactQuill
      theme="snow"
      value={content}
      onChange={(value) => setContent(value)}
      className="w-full"
    />
  );
};

export default Editor;
