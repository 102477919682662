import classNames from "classnames";
import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AddContactDialog from "./contact/add-contact-dialog";
import {
  EllipsisVerticalIcon,
  EyeIcon,
  PencilIcon,
  PlayIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { CAMPAGNE_NEW } from "../../../core/constants/layout";

const secondTabs = [
  {
    name: "Tous (0)",
    href: "#",
    current: true,
    state: 0,
  },
  {
    name: "Brouillons (1)",
    href: "#",
    current: false,
    state: 1,
  },
];

const people = [
  {
    title: "Campagne Test ",
    name: "Merlin",
    numero: "+237655413390",
    status: "brouillon",
    shudle: "dans 17 jours",
    time: "24 mai 2024 10h15",
    progress: "1 destinataires",
  },
];

export default function FollowCampagne() {
  const navigate = useNavigate();

  const [statusTab, setStatusTab] = React.useState<number>(0);

  const handleNavigate = () => {
    navigate(`${CAMPAGNE_NEW}`);
    window.location.reload();
  };
  return (
    <>
      <div className=" max-w-6xl mx-auto ">
        <div className="flex flex-col divide-y ">
          <div className=" px-4 py-4 gap-x-4  ">
            <div className="">
              <div className="mt-8 ">
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher une campagne"
                    />
                    <div className="flex items-center gap-x-2">
                      <a
                        onClick={() => handleNavigate()}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm cursor-pointer"
                      >
                        Créer une campagne
                      </a>
                    </div>
                  </div>
                  <div className=" ">
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              statusTab === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    {statusTab === 0 ? (
                      <>
                        <div className="px-4 sm:px-6 lg:px-8 h-full">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300 h-full">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                      >
                                        NOM
                                      </th>

                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        NUMÉRO
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        STATUS
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        DATE PRÉVUE
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        AUTEUR
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        PROGRÈS
                                      </th>
                                      <th
                                        scope="col"
                                        className="relative py-3 pl-3 pr-4 sm:pr-0"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {people.map((person) => (
                                      <tr key={person.numero}>
                                        <td className="whitespace-nowrap flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                          <span className="text-purple-600">
                                            {person.title}
                                          </span>
                                          <span className="text-xs font-light">
                                            Créé il y a 11 heures
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {person.numero}
                                        </td>

                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {person.status}
                                        </td>
                                        <td className="whitespace-nowrap  flex-col px-3 py-4 text-sm text-gray-500">
                                          {person.shudle} <br />
                                          <span className="text-xs font-light">
                                            {person.time}
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap flex px-3 py-4 text-sm text-gray-500">
                                          <div className="rounded-full bg-purple-300 p-2">
                                            <span className="uppercase  text-purple-600 ">
                                              {person.name.slice(0, 2)}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 font-light py-4 text-sm text-gray-500">
                                          <span>{person.progress}</span>
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                          <Menu
                                            as="div"
                                            className="relative inline-block text-left"
                                          >
                                            <div>
                                              <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                                <EllipsisVerticalIcon className="h-6 w-6" />
                                              </Menu.Button>
                                            </div>
                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                            >
                                              <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                <div className="px-1 py-1 ">
                                                  <div className="px-1 py-1">
                                                    <Menu.Item>
                                                      {({ active }) => (
                                                        <button
                                                          className={`${
                                                            active
                                                              ? "bg-purple-500 text-white"
                                                              : "text-gray-900"
                                                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                        >
                                                          {active ? (
                                                            <EyeIcon
                                                              className="mr-2 h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          ) : (
                                                            <EyeIcon
                                                              className="mr-2 h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          )}
                                                          Ouvrir
                                                        </button>
                                                      )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                      {({ active }) => (
                                                        <button
                                                          className={`${
                                                            active
                                                              ? "bg-purple-500 text-white"
                                                              : "text-gray-900"
                                                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                        >
                                                          {active ? (
                                                            <PencilIcon
                                                              className="mr-2 h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          ) : (
                                                            <PencilIcon
                                                              className="mr-2 h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          )}
                                                          Modifier
                                                        </button>
                                                      )}
                                                    </Menu.Item>
                                                  </div>

                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        className={`${
                                                          active
                                                            ? "bg-purple-500 text-white"
                                                            : "text-gray-900"
                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                      >
                                                        {active ? (
                                                          <PlayIcon
                                                            className="mr-2 h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        ) : (
                                                          <PlayIcon
                                                            className="mr-2 h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        )}
                                                        Commencer
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <button
                                                        className={`${
                                                          active
                                                            ? "bg-purple-500 text-white"
                                                            : "text-gray-900"
                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                      >
                                                        {active ? (
                                                          <TrashIcon
                                                            className="mr-2 h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        ) : (
                                                          <TrashIcon
                                                            className="mr-2 h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        )}
                                                        Supprimer
                                                      </button>
                                                    )}
                                                  </Menu.Item>
                                                </div>
                                              </Menu.Items>
                                            </Transition>
                                          </Menu>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucun campagne trouvé
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Vous pouvez essayer avec une autre recherche ou
                          modifier les filtres de recherche
                        </h5>
                        <div className="flex items-center gap-x-2">
                          <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm">
                            Créer une campagne
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
