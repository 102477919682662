import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DashboardIcon,
  HistoryIcon,
  MessageIcon,
} from "../../components/icons";
import { NavigationInterface } from "../models";
import {
  ChatBubbleLeftRightIcon,
  CurrencyDollarIcon,
  ForwardIcon,
  IdentificationIcon,
  MegaphoneIcon,
  PhotoIcon,
  RectangleGroupIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const navigationsList: NavigationInterface[] = [
  {
    name: "Dashboard",
    href: "/",
    icon: Squares2X2Icon,
    children: [
      {
        name: "Marketing",
        href: "#",
        icon: Squares2X2Icon,
      },
      {
        name: "Commercial",
        href: "#db-commercial",
        icon: Squares2X2Icon,
      },
      {
        name: "Operationnel",
        href: "#db-opperationnel",
        icon: Squares2X2Icon,
      },
      {
        name: "Satif. Client",
        href: "#db-satisfaction-client",
        icon: Squares2X2Icon,
      },
      {
        name: "Chats",
        href: "#db-conversation-chat",
        icon: Squares2X2Icon,
      },
    ],
    color: "gray",
  },
  {
    name: "Conversations",
    href: "/conversations",
    icon: ChatBubbleLeftRightIcon,
    children: [
      {
        name: "Dashboard",
        href: "#",
        icon: DashboardIcon,
      },
      { name: "Messages", href: "#messages", icon: MessageIcon },
      // { name: "History", href: "#history", icon: HistoryIcon },
    ],
    color: "green",
  },

  {
    name: "Campagnes",
    href: "/campagnes",
    icon: MegaphoneIcon,
    children: [
      {
        name: "Dashboard",
        href: "#",
        icon: Squares2X2Icon,
      },
      {
        name: "Leads",
        href: "#cpg-leads",
        icon: UserGroupIcon,
      },
      {
        name: "Suivi des campagnes",
        href: "#cpg-follow",
        icon: ForwardIcon,
      },
      {
        name: "Créer",
        href: "#cpg-new",
        icon: SquaresPlusIcon,
      },
      {
        name: "Contact",
        href: "#cpg-contact",
        icon: IdentificationIcon,
      },
      {
        name: "Template",
        href: "#cpg-template",
        icon: RectangleGroupIcon,
      },
      {
        name: "Media",
        href: "#cpg-media",
        icon: PhotoIcon,
      },
    ],
    color: "purple",
  },
  {
    name: "CRM Clients",
    href: "#",
    icon: UsersIcon,
    children: [],
    color: "green",
  },
  {
    name: "Finances",
    href: "#",
    icon: CurrencyDollarIcon,
    children: [],
    color: "green",
  },
];

interface Props {
  children?: ReactNode;
}

export const LayoutContext = createContext({
  selectChildren: "",
  navigations: [{}],
  asideOpen: true,
  handleOpenAsude: () => {},
  handleChildren: (value: string) => {},
});

export const LayoutProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [asideOpen, setAsideOpen] = useState<boolean>(true);

  const [navigations, setNavigations] = useState<NavigationInterface[]>(
    navigationsList
  );

  const [selectChildren, setSelectChildren] = useState<string>(
    location.hash ? location.hash : "#"
  );

  useEffect(() => {}, []);

  const handleOpenAsude = () => {
    setAsideOpen(!asideOpen);
  };

  const handleChildren = (value: string) => {
    setSelectChildren(value);
    navigate(value);
  };

  const value = {
    handleOpenAsude,
    asideOpen,
    navigations,
    selectChildren,
    handleChildren,
  };
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
export const useLayoutContext = () => useContext(LayoutContext);
