import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import {
  CloudArrowDownIcon,
  DocumentArrowDownIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

interface StepFourProps {
  handleNext: () => void;
}

const tabs = [
  {
    name: "Télécharger des contacts",
    href: "#",
    icon: DocumentArrowDownIcon,
    current: true,
    state: 0,
  },
  {
    name: "Sélectionner des contacts",
    href: "#",
    icon: UserGroupIcon,
    current: false,
    state: 1,
  },
];

const secondTabs = [
  {
    name: "Destinataires (0)",
    href: "#",
    current: true,
    state: 0,
  },
  {
    name: "Tous",
    href: "#",
    current: false,
    state: 1,
  },
  {
    name: "Groups",
    href: "#",
    current: false,
    state: 2,
  },
  {
    name: "Non attribué",
    href: "#",
    current: false,
    state: 3,
  },
  {
    name: "Attribué",
    href: "#",
    current: false,
    state: 4,
  },
  {
    name: "Résolu",
    href: "#",
    current: false,
    state: 5,
  },
];

export default function StepFour({ handleNext }: StepFourProps) {
  const [statusTab, setStatusTab] = React.useState<number>(0);
  return (
    <div className="border mt-8">
      <div className="flex flex-col divide-y">
        <div className=" px-4 py-4 gap-x-4 ">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)!.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="flex justify-center">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.name}
                      onClick={() => setStatusTab(tab.state)}
                      className={classNames(
                        statusTab === tab.state
                          ? "border-purple-500 text-purple-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      <tab.icon
                        className={classNames(
                          statusTab === tab.state
                            ? "text-purple-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
            {statusTab === 0 ? (
              <div className="mt-8">
                <div className="rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-blue-800">
                        Téléchargez en masse des contacts à partir de feuilles
                        de calcul CSV, Google Sheets ou Excel.
                      </h3>
                    </div>
                  </div>
                </div>
                <h5 className="text-base font-semibold text-gray-600 mb-2 mt-4">
                  Importer un fichier à partir d'une URL
                </h5>
                <div className="flex items-center gap-2 ">
                  <input
                    type="text"
                    className="border rounded-sm h-10 w-full px-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                    placeholder="Entrez lvotre URL ici"
                  />
                  <button className="text-white h-10 bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-xs">
                    Importer
                  </button>
                </div>
                <h6 className="text-sm font-normal text-purple-600 mb-2 mt-4">
                  Découvrez comment importer depuis Google Sheets
                </h6>
                <div className="rounded-md border-4 border-dashed border-purple-500 bg-gray-50 p-4 shadow-md w-full">
                  <label
                    htmlFor="upload"
                    className="flex flex-col py-10 items-center gap-2 cursor-pointer"
                  >
                    <CloudArrowDownIcon className="text-purple-600 h-12 w-12" />
                    <h5 className="text-sm font-semibold text-gray-600 ">
                      Glissez et déposez le fichier ou cliquez ici pour en
                      sélectionner un
                    </h5>
                    <span className="text-xs font-light text-gray-400">
                      Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à
                      5 Mo)
                    </span>
                  </label>
                  <input id="upload" type="file" className="hidden" />
                </div>
                <span className="text-xs font-light text-gray-400">
                  Téléchargez un exemple de fichier CSV ou un exemple de fichier
                  Excel
                </span>
              </div>
            ) : (
              <div className="mt-8">
                <div className="flex flex-col divide-y border">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher des contacts"
                    />
                    <div className="flex items-center gap-x-2">
                      <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                        Filtre
                      </button>
                      <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                        Exporter
                      </button>
                      <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm">
                        Aujouter le contact
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              0 === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                      <img
                        src="/assets/images/campagnes/not_found.png"
                        alt=""
                        className="h-40 w-40"
                      />
                      <h1 className="text-2xl font-semibold text-purple-600">
                        Aucun contact disponible pour l'instant
                      </h1>
                      <h5 className="text-sm font-normal text-gray-500">
                        Les contacts WhatsApp n'ont pas encore été synchronisés,
                        vous pouvez réessayer dans quelques minutes ou créer des
                        contacts manuellement
                      </h5>
                      <div className="flex items-center gap-x-2">
                        <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm">
                          Ajouter un nouveau contact
                        </button>
                        <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                          Recharger
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-3">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
            Annuler
          </button>
          <button
            onClick={() => handleNext()}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
}
