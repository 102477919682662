import React from "react";
import CreateCampagne from "./new-campagne/create-campage";
import OnboardingCampagne from "./new-campagne/onboarding-campagne";

export default function NewCampagne() {
  const [step, setStep] = React.useState<number>(0);
  return (
    <main className="max-w-7xl mx-auto">
      {step === 0 ? (
        <CreateCampagne handleCreate={() => setStep(1)} />
      ) : (
        <OnboardingCampagne />
      )}
    </main>
  );
}
