import React, { Fragment } from "react";
import { AddIcon, SearchIcon } from "../components/icons";
import { UserMessage } from "../components";
import { userChats } from "../utils/mocks";
import classNames from "classnames";

interface MessagesProps {
  openAside: boolean;
}

export default function Messages({ openAside }: MessagesProps) {
  return (
    <aside
      className={classNames(
        { "left-40": openAside },
        { "left-20": !openAside },
        "transition-all duration-300 ease-in-out fixed inset-y-0  hidden w-80 overflow-y-auto border-r border-gray-200 xl:block mt-12"
      )}
    >
      <div className="flex justify-between items-center px-4 sm:px-6 lg:px-4 py-6">
        <div className="flex items-center gap-x-2">
          <h3 className="font-semibold text-lg">Messages</h3>
          <h1 className="text-xs bg-gray-200 px-2 rounded-xl">12</h1>
        </div>
        <AddIcon className="text-green-600 h-6 w-6" />
      </div>
      <hr />
      <div className="w-full px-3">
        <div className="h-16 bg-primary-white w-full">
          <div className="h-full flex flex-col justify-center items-center relative">
            <SearchIcon className=" h-4 w-4 absolute left-4 text-gray-500" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search messages..."
              className="bg-gray-100 text-gray-500 text-xs placeholder:text-gray-400 placeholder:text-sm placeholder:font-light font-light h-10 w-full rounded-xl outline-none pl-12 pr-16"
            />
          </div>
        </div>
        <div className="w-full divide-y">
          {userChats.map((item, key) => {
            return (
              <UserMessage
                className="hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                key={key}
                userChat={item}
              />
            );
          })}
        </div>
      </div>
    </aside>
  );
}
