import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { SettingIcon } from "../components/icons";
import { useLayoutContext } from "../core/context/layout-context";
import { NavigationInterface } from "../core/models";
import { useThemeContext } from "../core/context/theme-context";

interface HeaderProps {
  openAside: boolean;
  setAsideOpen: () => void;
}

export default function Header({ openAside, setAsideOpen }: HeaderProps) {
  const location = useLocation();
  const { navigations } = useLayoutContext();
  const { color, handleColor } = useThemeContext();

  return (
    <header
      className={classNames(
        { "lg:pl-44": openAside },
        { "lg:pl-24": !openAside },
        `${
          color === "green"
            ? "bg-green-900"
            : color === "purple"
            ? "bg-purple-900"
            : "bg-gray-900"
        }`,
        "transition-all duration-300 ease-in-out flex justify-between items-center fixed top-0 h-[50px] border w-full xl:pr-6 z-20 shadow-2xl"
      )}
    >
      <nav className="h-full flex items-center">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-white hidden md:block"
          onClick={setAsideOpen}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <ul className="flex items-center gap-x-8 ml-4">
          {navigations.map((elmt, i) => {
            const item = elmt as NavigationInterface;
            return (
              <Link
                key={i}
                to={item.href}
                onClick={() => handleColor(item.color)}
              >
                <li
                  className={classNames(
                    {
                      " ": location.pathname === item.href,
                    },
                    {
                      "text-gray-300 font-light":
                        location.pathname !== item.href,
                    },
                    location.pathname === item.href
                      ? `${
                          color === "green"
                            ? "bg-green-100 text-green-700"
                            : color === "purple"
                            ? "bg-purple-100 text-purple-700"
                            : "bg-gray-100 text-gray-700"
                        } font-medium`
                      : "text-gray-300 font-light",
                    `${
                      color === "green"
                        ? "hover:text-green-700"
                        : color === "purple"
                        ? "hover:text-purple-700"
                        : "hover:text-gray-700"
                    }`,
                    `${
                      color === "green"
                        ? "hover:bg-green-100"
                        : color === "purple"
                        ? "hover:bg-purple-100"
                        : "hover:bg-gray-100"
                    }`,
                    "text-sm cursor-pointer   flex items-center gap-x-2 px-2 py-[5.5px] rounded-2xl group"
                  )}
                >
                  <item.icon
                    className={classNames(
                      location.pathname === item.href
                        ? `${
                            color === "green"
                              ? "text-green-600"
                              : color === "purple"
                              ? "text-purple-600"
                              : "text-gray-600"
                          }`
                        : "text-gray-300",
                      `${
                        color === "green"
                          ? "group-hover:text-green-600"
                          : color === "purple"
                          ? "group-hover:text-purple-600"
                          : "group-hover:text-gray-600"
                      }`,
                      "h-4 w-4 shrink-0 "
                    )}
                    aria-hidden="true"
                  />
                  {item.name}{" "}
                </li>
              </Link>
            );
          })}
        </ul>
      </nav>
      <div className="relative flex items-center gap-x-6">
        <div className="flex items-center gap-4">
          <BellIcon className="h-6 w-6 text-white" />
          <SettingIcon className="h-6 w-6 text-white" />
        </div>
        <div className="flex flex-row gap-x-2">
          <img
            src="https://randomuser.me/api/portraits/men/20.jpg"
            className="rounded-full"
            width="40"
          />
          <div className="flex items-center gap-4">
            <div className="flex flex-col">
              <div className="flex flex-row items-start gap-2">
                <span className="text-white text-sm font-medium">Lavern</span>
                <span className="text-white text-sm font-medium">L.</span>
              </div>
              <span className="text-[10px] text-gray-200">Frontend Dev</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
